import React from "react";
import Layout from "../components/reusable/Layout";
import { Link, graphql } from "gatsby";
import Seo from "../components/reusable/Seo";
import { useTranslation } from "react-i18next";
import { InfoSection, ContactSection } from "../styles/pages/terms";
import { Bg, Text } from "../components/ui";
import { Button, Stack } from "@mui/material";

const Terms = () => {
  const { t } = useTranslation("terms");
  return (
    <>
      <Seo
        title="Términos y Condiciones de nuestra plataforma de talento."
        description="Queremos que tengas la mejor experiencia posible en nuestra plataforma de talento."
        image="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fpreviews%2FLANDING%20EMPRESAS.png?alt=media&token=3dd56d07-4544-4eef-ab5c-5b53a475137e"
      />
      <Layout>
        <Bg.Image img="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fterms-and-conditions%2F30d9f0443de18dfa879eb1204b14a8d9.png?alt=media&token=f4c9908c-b895-4a86-b2c8-5650e7393622">
          <Stack
            height="350px"
            padding="12rem 80px 8rem 100px"
            justifyContent="center"
          >
            <Text.SubTitle
              textAlign="start"
              fontSize="2rem"
              text={t("heroTitle")}
              color="primary.white"
            />
            <Text.Title
              textAlign="start"
              fontSize="3rem"
              text={t("heroDesc")}
              color="primary.white"
            />
          </Stack>
        </Bg.Image>
        <InfoSection>
          <div className="info-section main-landing-container-column">
            <Stack>
              <Text.Title textAlign="start">{t("heroTitle")}</Text.Title>
              <Text.Body textAlign="start" fontSize="1.8rem">
                {t("termsFirstDesc")}
              </Text.Body>
            </Stack>
            <div className="info-section-list">
              <article>
                <Stack spacing={3} mt={3}>
                  <Text.Title textAlign="start" fontSize="2.4rem">
                    {t("termsSecondTitle")}
                  </Text.Title>
                  <Text.Body textAlign="start" fontSize="1.8rem">
                    {t("termsSecondDesc")}
                  </Text.Body>
                </Stack>
              </article>
              <article>
                <Stack spacing={3} mt={3}>
                  <Text.Title textAlign="start" fontSize="2.4rem">
                    {t("termsThirdTitle")}
                  </Text.Title>
                  <Text.Body textAlign="start" fontSize="1.8rem">
                    {t("termsThirdDesc")}
                  </Text.Body>
                </Stack>
              </article>
            </div>
          </div>
        </InfoSection>
        <Bg.Gradient>
          <ContactSection>
            <div className="contact-section main-landing-container-row">
              <Stack spacing={4}>
                <Stack spacing={4}>
                  <Text.Title textAlign="start">
                    {t("preFooterTitle")}
                  </Text.Title>
                  <Text.Body textAlign="start" fontSize="1.8rem">
                    {t("preFooterDesc")}
                  </Text.Body>
                </Stack>
                <Text.Body textAlign="start" fontSize="1.8rem">
                  {t("contactMessage")}{" "}
                  <a
                    href="mailto:legal@lapieza.io"
                    style={{
                      color: "#FFA000",
                      textDecoration: "none",
                    }}
                  >
                    legal@lapieza.io
                  </a>
                </Text.Body>
                <Text.Body fontSize="1.8rem" textAlign="start">
                  {t("contactMessage2")}
                </Text.Body>
                <Button
                  sx={{
                    width: "300px",
                  }}
                >
                  <Link
                    className="contact-button"
                    to="/contact"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    {t("contactTeam")}
                  </Link>
                </Button>
              </Stack>
              <figure>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fcarbon-neutrality%2FquestionPiece.png?alt=media&token=a49c3bcb-2738-4fe0-85a9-aa40b39f2b0e"
                  alt="Neutralidad de carbono | LaPieza"
                />
              </figure>
            </div>
          </ContactSection>
        </Bg.Gradient>
      </Layout>
    </>
  );
};

export default Terms;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
